import React from 'react';

import { MenuItem, Select, SelectProps, styled } from '@mui/material';

export type Props = SelectProps & { options: (Option & { disabled?: boolean })[] };

export const SelectStyled = styled(Select)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  padding: '0rem',
  boxSizing: 'border-box',
  borderRadius: '0.8rem',
  minWidth: '8rem',

  '& .MuiSelect-select': {
    padding: '0.6rem',
    fontSize: '1rem',
  },

  '& .MuiMenuItem-root': {
    fontSize: '0.9rem',
  },
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  fontSize: '1rem',
}));

export function Dropdown({ options, ...rest }: Props) {
  return (
    <SelectStyled
      labelId='frulow-select-dropdown'
      id='frulow-select-dropdown'
      value={'default'}
      {...rest}
      MenuProps={{
        sx: { bgcolor: '#06185434' },
        slotProps: {
          paper: {
            sx: {
              background: 'common.white',
              width: '13rem',
              maxHeight: '20rem',
              borderRadius: '0.9rem',
            },
          },
        },
      }}
    >
      {options.map((item) => (
        <MenuItemStyled disabled={item.disabled} key={item.value} value={item.value}>
          {item.label}
        </MenuItemStyled>
      ))}
    </SelectStyled>
  );
}
