import React, { useMemo } from 'react';

import {
  Avatar,
  Box,
  Button,
  IconButton,
  Link,
  Paper,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { useIntersectionObserver, useToggle } from '@uidotdev/usehooks';
import { BsEyeSlash, BsFlag, BsThreeDots, BsTrash } from 'react-icons/bs';

import { Popper } from '@components/Popper';
import { StackRow } from '@components/StackRow';
import { TextTruncateWithoutExpand } from '@components/TexTruncate';
import useAuthor from '@hooks/useAuthor';
import usePermission from '@hooks/usePermission';
import { isValidArray } from '@utility/array';
import { preventDefaultWrapper } from '@utility/button';
import { getShortestDuration } from '@utility/dates';
import { FeedPostTrendingPostsResult } from 'api/feed/types';

import CardActions from './CardActions';
import ImageContent from './ImageContent';
// eslint-disable-next-line import/default, import/namespace, import/no-named-as-default
import useCardItem from './useCardItem';

interface Props {
  index: number;
  data: FeedPostTrendingPostsResult;
}
function CardItem({ data, index }: Readonly<Props>) {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0.05,
    root: null,
    rootMargin: '0px',
  });

  const { isOwner, isUserValid } = usePermission();
  const { handleFollowUseById, isFollowed, withLink } = useAuthor({
    author: {
      fullName: data.authorFullName,
      id: data.authorId,
      profilePicUrl: data.authorAvatar,
      userName: data.authorUserName,
    },
  });

  const { handlePosVote, handlePostDelete } = useCardItem({
    postId: data.id,
    inView: !!entry?.isIntersecting,
    voteValue: data.viewerVoteValue,
    dislikes: data.dislikes,
    likes: data.likes,
  });

  const [expandedContent, toggleExpandedContent] = useToggle(false);

  const PopperElement = useMemo(() => {
    return (
      <Box
        sx={{ width: '10em', bgcolor: 'white', py: 1 }}
        onClick={() => preventDefaultWrapper(undefined, true)}
        component='div'
      >
        {isOwner(data.authorId) && (
          <Button
            startIcon={<BsTrash />}
            fullWidth
            variant='text'
            sx={{ p: 0.5, fontSize: '1rem', boxShadow: 'none' }}
            onClick={handlePostDelete}
          >
            Delete Post
          </Button>
        )}
        {/* <Button
          startIcon={<BsEyeSlash />}
          fullWidth
          variant='text'
          sx={{ p: 0.5, fontSize: '1rem', boxShadow: 'none' }}
        >
          Hide Post
        </Button>*/}

        <Button
          startIcon={<BsFlag />}
          fullWidth
          variant='text'
          sx={{ p: 0.5, fontSize: '1rem', boxShadow: 'none' }}
          disabled
        >
          Report
        </Button>
      </Box>
    );
  }, [data.authorId, handlePostDelete, isOwner]);

  return (
    <Paper variant='outlined' ref={ref} sx={{ bgcolor: 'white', boxShadow: 'none' }}>
      <StackRow alignItems='center' justifyContent='space-between' sx={{ p: 2 }}>
        {withLink(
          <Avatar src={data.authorAvatar} sx={{ width: '2em', height: '2em' }}>
            {data.authorFullName.slice(0, 1)}
          </Avatar>
        )}
        <StackRow alignItems='center' justifyContent='space-between' sx={{ width: '100%', pl: 2 }}>
          {withLink(
            <StackRow spacing={2} alignItems='center' justifyContent='flex-start'>
              <Box>
                <Typography sx={{ fontSize: '0.9rem', fontWeight: 700 }}>
                  {data.authorFullName}
                  <Typography
                    component='span'
                    sx={{ fontSize: '0.9rem', color: 'GrayText', ml: 1 }}
                  >
                    {getShortestDuration(data.createdAt)}
                  </Typography>
                </Typography>
                <Typography
                  sx={{ fontSize: '0.8rem', color: 'GrayText' }}
                >{`@${data.authorUserName}`}</Typography>
              </Box>
            </StackRow>
          )}

          <StackRow spacing={2} alignItems='center' justifyContent='flex-end'>
            {!isFollowed.data?.followed && !isOwner(data.authorId) && !!isUserValid() && (
              <Button
                sx={{
                  height: '2.3rem',
                  width: '5.6rem',
                  px: 2,
                  justifySelf: 'flex-start',
                  fontSize: '0.9rem',
                }}
                onClick={handleFollowUseById}
                disabled={isFollowed.isFetching}
              >
                Follow
              </Button>
            )}
            <Tooltip title='More'>
              <Popper popperElement={PopperElement}>
                <IconButton size='small'>
                  <BsThreeDots />
                </IconButton>
              </Popper>
            </Tooltip>
          </StackRow>
        </StackRow>
      </StackRow>
      {data.text && (
        <Box sx={{ px: 2, pb: isValidArray(data.pictures) ? 0 : 2 }}>
          <Typography sx={{ fontSize: '1rem', whiteSpace: 'pre-line' }}>
            <TextTruncateWithoutExpand
              length={expandedContent ? data.text.length : 250}
              text={data.text}
            />
            {data.text.length > 250 && !expandedContent && (
              <SeeMoreButton underline='hover' onClick={() => toggleExpandedContent()}>
                See more
              </SeeMoreButton>
            )}
          </Typography>
        </Box>
      )}
      {isValidArray(data.pictures) && (
        <Box sx={{ px: 2, py: 1.6, width: '100%' }}>
          <ImageContent images={data.pictures} />
        </Box>
      )}
      <Box sx={{ px: 2, width: '100%' }}>
        <CardActions {...data} onVote={handlePosVote} />
      </Box>
    </Paper>
  );
}

export default CardItem;

const SeeMoreButton = styled(Link)(({ theme }) => ({
  fontSize: '0.95rem',
  cursor: 'pointer',
}));
