import React, { useCallback, useEffect, useState } from 'react';

import { Box, colors, styled } from '@mui/material';
import { FreeMode, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface ImageItemProps {
  src: string;
}
// Assuming ImageItem is a component that accepts the objectFit property
const DynamicImage = ({ src }: ImageItemProps) => {
  // State to store the image properties
  const [objectFit, setObjectFit] = useState('cover');

  const getImageProperties = useCallback((src: string) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;

        // Adjust the threshold as needed
        const oversizeThreshold = 1.4;

        setObjectFit(aspectRatio > oversizeThreshold ? 'cover' : 'contain');
      };
      img.src = src;
      resolve(src);
    });
  }, []);

  useEffect(() => {
    const fetchImageProperties = async () => {
      await getImageProperties(src);
    };

    fetchImageProperties();
  }, [getImageProperties, src]);

  return <ImageItem src={src} sx={{ objectFit: objectFit || 'cover' }} />;
};

interface Props {
  images: string[];
}
function ImageContent({ images }: Readonly<Props>) {
  const [itemWidth, setItemWidth] = useState<string | undefined>(undefined);

  const modules = [FreeMode, Pagination];
  if (images.length > 1) {
    modules.push(Navigation);
  }

  const handleOnInit = useCallback((swiper: SwiperClass) => {
    setItemWidth(`${swiper.width}px`);
  }, []);

  return (
    <SwiperStyled
      slidesPerView={1}
      spaceBetween={30}
      edgeSwipeDetection
      modules={modules}
      className='container'
      allowTouchMove
      lazyPreloadPrevNext={2}
      mousewheel={true}
      direction='horizontal'
      onInit={handleOnInit}
      pagination={{ clickable: true }}
      centeredSlides
    >
      {images.map(
        (item) =>
          itemWidth && (
            <SwiperSlideStyled key={item}>
              <Box
                sx={{
                  width: itemWidth,
                  bgcolor: colors.blueGrey[50],
                  height: '20em',
                  borderRadius: 3,
                  overflow: 'hidden',
                }}
              >
                <DynamicImage src={item} />
              </Box>
            </SwiperSlideStyled>
          )
      )}
    </SwiperStyled>
  );
}

export default ImageContent;

const SwiperSlideStyled = styled(SwiperSlide)``;

const SwiperStyled = styled(Swiper)(({ theme }) => ({
  // Custom styles for the navigation buttons
  '.swiper-button-next, .swiper-button-prev': {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[2],
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:after': {
      fontSize: '1rem',
    },
    '&:hover': {
      backgroundColor: '#ccc',
    },
  },

  '.bullets-dots': {},
}));

const ImageItem = styled('img')(({}) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}));
