// SocketContext.tsx

import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { Socket, io } from 'socket.io-client';

import { baseURL } from '@constants/index';
import infiniteQueryUtils from '@utility/infiniteQuery';
import { getSessionToken } from '@utility/session';
import { createPostEventName } from '@utility/socket';
import { queryKeys } from 'api';
import { FeedPostLiveStats, FeedPostTrendingPostsResult } from 'api/feed/types';

interface SocketContextProps {
  children: ReactNode;
}

type SubscribePostIdAction = (postId: number) => void;
export type ModifyDataLocally = (
  id: number,
  newData: Partial<FeedPostTrendingPostsResult>,
  automaticUpdate?: boolean
) => void;
interface SocketContextValue {
  socket: Socket | null;
  subscribePostId: SubscribePostIdAction | null;
  modifyDataLocally: ModifyDataLocally | null;
  subscribedPosts: number[];
}

const INTERVAL = process.env.NODE_ENV === 'development' ? 10000 : 30000;

const SocketContext = createContext<SocketContextValue>({
  socket: null,
  subscribePostId: null,
  modifyDataLocally: null,
  subscribedPosts: [],
});

export const useSocket = () => {
  return useContext(SocketContext);
};

const socket = io(process.env.NODE_ENV === 'development' ? 'http://localhost:3500' : baseURL, {
  auth: { token: getSessionToken() },
}); // Update with your server URL

const SocketProvider: React.FC<SocketContextProps> = ({ children }) => {
  const queryClient = useQueryClient();

  const [postIds, setPostIds] = useState<number[]>([]);

  const modifyDataLocally: ModifyDataLocally = useCallback(
    (id, newData, automaticUpdate) => {
      const state = queryClient.getQueryState([queryKeys.getTrendingFeedPosts]);

      // to keep local data if it's updated recently
      if (automaticUpdate && moment().diff(state?.dataUpdatedAt, 'seconds') <= 30) return;

      queryClient.setQueryData(
        [queryKeys.getTrendingFeedPosts],
        (oldData) => {
          if (oldData === undefined) {
            queryClient.refetchQueries([queryKeys.getTrendingPosts]);
            return undefined;
          }

          return {
            ...oldData,
            ...infiniteQueryUtils.modifyDataById(oldData as any, id, newData as any),
          };
        },
        { updatedAt: +moment() }
      );
    },
    [queryClient]
  );

  useEffect(() => {
    socket.connect();
    socket.on('connect', () => {
      console.log('Connected');
      socket.on(createPostEventName('stats:update'), (posts: FeedPostLiveStats[]) => {
        posts.map((item) => modifyDataLocally(item.id, item, true));
      });
    });
    return () => {
      socket.off();
      socket.disconnect();
    };
  }, []);

  const emitGetStats = useCallback(() => {
    if (postIds.length) socket.emit(createPostEventName('get:stats'), { postIds });
  }, [postIds]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (socket.connected) {
        emitGetStats();
      }
    }, INTERVAL);

    return () => clearInterval(intervalId);
  }, [postIds, emitGetStats]);

  const subscribePostId: SubscribePostIdAction = (postId) => {
    // Remove the first occurrence if the ID already exists
    const updatedIds = postIds.includes(postId) ? postIds.filter((id) => id !== postId) : postIds;
    // Add the new ID to the end
    const newIds = [...updatedIds, postId];
    // Ensure the array never exceeds 25 items
    const truncatedIds = newIds.slice(-8);
    setPostIds(truncatedIds);
  };

  const value: SocketContextValue = {
    socket,
    subscribePostId,
    modifyDataLocally,
    subscribedPosts: postIds,
  };

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export default SocketProvider;
