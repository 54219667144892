import React from 'react';

import { Avatar, Button, IconButton, Paper, colors, lighten } from '@mui/material';
import { useToggle } from '@uidotdev/usehooks';
import { BsCameraVideo, BsImages } from 'react-icons/bs';

import { useAppSelector } from '@app/hooks';
import { getRootUser } from '@app/root/slice';
import { StackRow } from '@components/StackRow';
import useFeatures from '@hooks/useFeatures';

import AddPostModal from './AddPostModal';

function AddPostBar() {
  const { videosPosts } = useFeatures();
  const user = useAppSelector(getRootUser);

  const [addPostModalShow, toggleAddPostModalShow] = useToggle(false);

  return (
    <>
      <Paper sx={{ bgcolor: 'white', p: 1, px: 2, height: '3.8rem', borderRadius: 3, mb: 3 }}>
        <StackRow spacing={1} alignItems='center' justifyContent='flex-start'>
          <Avatar src={user?.profilePicUrl as string}>{user?.firstName?.slice(0, 1)}</Avatar>
          <Button
            variant='text'
            fullWidth
            onClick={() => toggleAddPostModalShow()}
            sx={{
              justifyContent: 'flex-start',
              p: 1,
              px: 2,
              bgcolor: lighten(colors.blueGrey[50], 0.2),
              fontSize: '0.95rem',
              color: 'GrayText',
              fontWeight: 400,
              textTransform: 'none',
            }}
          >
            Share updates...
          </Button>
          <IconButton onClick={() => toggleAddPostModalShow()}>
            <BsImages />
          </IconButton>
          {videosPosts && (
            <IconButton>
              <BsCameraVideo />
            </IconButton>
          )}
        </StackRow>
      </Paper>
      {addPostModalShow && (
        <AddPostModal open={addPostModalShow} onClose={toggleAddPostModalShow} />
      )}
    </>
  );
}

export default AddPostBar;
