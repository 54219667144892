import { useCallback, useEffect } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

import { useConfirmBox } from '@hoc/confirm.context';
import { ModifyDataLocally, useSocket } from '@hoc/SocketContext';
import usePermission from '@hooks/usePermission';
import { useToast } from '@hooks/useToast';
import infiniteQueryUtils, { calculateUpdatedStats } from '@utility/infiniteQuery';
import { queryKeys } from 'api';
import { delelteFeedPostById, voteFeedPostById } from 'api/feed';
import { FeedPostTrendingPostsResult } from 'api/feed/types';

type Props = {
  postId: number;
  inView: boolean;
  voteValue: FeedPostTrendingPostsResult['viewerVoteValue'];
} & Pick<FeedPostTrendingPostsResult, 'likes' | 'dislikes'>;

export type PostUpvoteAction = (isUpvote: boolean) => void;

const isValidValue = (x: any): number | null => {
  return ['string', 'number'].includes(typeof x) && Number.isNaN(+x) ? null : +x;
};

function useCardItem({ postId, voteValue, inView, likes, dislikes }: Props) {
  const queryClient = useQueryClient();
  const { promiseToast } = useToast();
  const { askConfirm } = useConfirmBox();

  const { secureCallback } = usePermission();

  const modifyDataLocally: ModifyDataLocally = useCallback(
    (id, newData, automaticUpdate) => {
      const state = queryClient.getQueryState([queryKeys.getTrendingFeedPosts]);

      // to keep local data if it's updated recently
      if (automaticUpdate && moment().diff(state?.dataUpdatedAt, 'seconds') <= 30) return;

      queryClient.setQueryData(
        [queryKeys.getTrendingFeedPosts],
        (oldData) => {
          if (oldData === undefined) {
            queryClient.refetchQueries([queryKeys.getTrendingPosts]);
            return undefined;
          }

          return {
            ...oldData,
            ...infiniteQueryUtils.modifyDataById(oldData as any, id, newData as any),
          };
        },
        { updatedAt: +moment() }
      );
    },
    [queryClient]
  );

  const postVote = useMutation((isUpvote: boolean) => voteFeedPostById(postId, isUpvote));
  const postDelete = useMutation(delelteFeedPostById);

  // const getUpdatedVoteValue = (isUpvote: boolean) => {
  //   const voteValueLatest = voteValue;
  //   if (voteValueLatest === undefined || voteValueLatest === null) return isUpvote ? +1 : -1;
  //   if (voteValueLatest === (isUpvote ? +1 : -1)) return undefined;
  //   return isUpvote ? +1 : -1;
  // };

  const handlePosVote: PostUpvoteAction = secureCallback((isUpvote: boolean) => {
    postVote.mutate(isUpvote, {
      onSuccess() {
        queryClient.refetchQueries([queryKeys.getTrendingFeedPosts], { type: 'all' });
      },
    });
  });

  const handlePostDelete = useCallback(async () => {
    try {
      const confirmed = await askConfirm(
        'Delete post?',
        'Are you sure. This action cannot be undone.'
      );

      if (!confirmed) return;

      await promiseToast(postDelete.mutateAsync(postId), {
        error: 'An error occurred while deleting post.',
        loading: 'Deleting post...',
        success: (data) => {
          queryClient.invalidateQueries([queryKeys.getTrendingFeedPosts], {
            refetchPage(lastPage, index, allPages: any[]) {
              return lastPage.pageData.some((sitem: any) => sitem.id === postId);
            },
          });
          return 'Successfully Created.';
        },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
  }, [askConfirm, postDelete, postId, promiseToast, queryClient]);

  return { handlePosVote, modifyDataLocally, handlePostDelete };
}

export default useCardItem;
