import React from 'react';

import { styled } from '@mui/material';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import useResponsive from '@hooks/useResponsive';
import { GetUserToFollowSuggestions } from 'api/feed/types';

import ProfileItem from './ProfileItem';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

interface Props {
  suggested: GetUserToFollowSuggestions[];
  slidePerView?: number;
}

function ProfileSuggestions({ suggested, slidePerView }: Props) {
  const { isDownLg } = useResponsive();
  return (
    <SwiperStyled
      slidesPerView={slidePerView ?? (isDownLg ? 2 : 3)}
      spaceBetween={isDownLg ? 10 : 30}
      freeMode={true}
      edgeSwipeDetection
      modules={[FreeMode, Navigation]}
      className='container'
      allowTouchMove
      lazyPreloadPrevNext={2}
      mousewheel={true}
      direction='horizontal'
      navigation
    >
      {suggested.map((item) => (
        <SwiperSlide key={item.id}>
          <ProfileItem data={item} />
        </SwiperSlide>
      ))}
    </SwiperStyled>
  );
}

export default ProfileSuggestions;

const SwiperStyled = styled(Swiper)(({ theme }) => ({
  padding: '0 1px',
  // Custom styles for the navigation buttons
  '.swiper-button-next, .swiper-button-prev': {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[2],
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:after': {
      fontSize: '1rem',
    },
    '&:hover': {
      backgroundColor: '#ccc',
    },

    '&.swiper-button-disabled': {
      display: 'none',
    },
  },
}));
