import { lazy, useCallback, useState } from 'react';

import {
  Box,
  Button,
  Chip,
  Container,
  Hidden,
  LinearProgress,
  Stack,
  Typography,
  colors,
  lighten,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { lowerCase, startCase } from 'lodash';
import { BsHash } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { AdsWrapper } from '@components/Ads';
import { ErrorState } from '@components/ErrorState';
import { Headers } from '@components/Headers';
import HorizontalScroller from '@components/HorizontalScroller';
import { CardSkeleton } from '@components/Skeletons';
import { AppRoutes } from '@constants/appRoutes';
import { fullHeightWithNav } from '@constants/index';
import useInfiniteQueryWithPagination from '@hooks/useInfiniteQueryWithPagination';
import useListMapper from '@hooks/useListMapper';
import usePermission from '@hooks/usePermission';
import { useRouter } from '@hooks/useRouter';
import AddPostBar from '@pages/Community/AddPostBar';
import Cards from '@pages/Community/Cards/Cards';
import ProfileSuggestions from '@pages/Community/ProfileSuggestions/ProfileSuggestions';
import EndComponent from '@pages/PostsByTag/EndComponent';
import { LazyRoute } from '@router/LazyRoute';
import { generateRandomArrayWithIndexes, isValidArray } from '@utility/array';
import { persistLDChangeInQueryData } from '@utility/functionHandlers';
import { rv } from '@utility/styles';
import { queryKeys } from 'api';
import { getUserToFollowSuggestions } from 'api/feed';
import { getRecentlyPublishedPaginated } from 'api/post';
import { TrendingPostsResponse } from 'api/post/types';
import { getTrendingTags } from 'api/tag';

import AdUnit from './AdUnit';
import Card from './Card';
import Footer from './Footer';
import useDetailsDrawer from './useDetailsDrawer';

const DetailsDrawer = lazy(() => import('./DetailsDrawer'));

const queryKey = [queryKeys.getTrendingPosts];

function HomePage() {
  const { push } = useRouter();
  const drawer = useDetailsDrawer();
  const queryClient = useQueryClient();
  const { isUserValid } = usePermission();

  const [dataQueryEnabled, setDataQueryEnabled] = useState(true);

  const infiniteRecentPublishedQuery = useInfiniteQueryWithPagination(
    [queryKeys.getRecentlyPublishedPaginated],
    ({ pageParam = 1 }) => getRecentlyPublishedPaginated(pageParam),
    1,
    { enabled: dataQueryEnabled }
  );

  const suggested = useQuery(
    [queryKeys.getUserToFollowSuggestions],
    () => getUserToFollowSuggestions(),
    { enabled: !!isUserValid() }
  );

  const trendingTags = useQuery([queryKeys.getTrendingTags], () => getTrendingTags(), {
    keepPreviousData: true,
  });

  const renderCard = useCallback(
    (listType: string) => (itemData: TrendingPostsResponse[number]) => {
      // Render your list item here
      return (
        <Card
          key={`list-${listType}-${itemData.id}`}
          {...itemData}
          onChange={persistLDChangeInQueryData(queryKey, queryClient, setDataQueryEnabled)}
        />
      );
    },
    [queryClient]
  );

  const rencentlyPublishedList = useListMapper<TrendingPostsResponse[number]>({
    list: infiniteRecentPublishedQuery.pageData.items,
    renderItem: renderCard('recently-published'),
    batchSize: 10,
    renderCustomItem() {
      return <AdsWrapper adClientId='ca-pub-9791957935297347' adSlot='3252015492' horizontal />;
    },
  });

  const openCommunity = () => push(AppRoutes.community);

  return (
    <>
      <Headers
        title={`Trending Products, Gadgets, Libraries — Frulow`}
        description={`Explore products, gadgets and libraries trending today on Frulow!`}
        keywords={trendingTags.data?.map((item) => item.name).join(',') ?? undefined}
      />
      <Container maxWidth='lg'>
        <Stack direction={{ sm: 'column', lg: 'row' }} sx={{ height: '100%' }} spacing={0}>
          <Box
            width={{ xs: '100%', lg: '60%' }}
            sx={{ borderRight: { xs: 'none', lg: '1px solid #eee' }, pr: rv(0, 2) }}
          >
            <Stack direction='row' alignItems='center' justifyContent='flex-start' sx={{ pl: 2 }}>
              <Box width='100%' sx={{ py: 2 }}>
                <Typography variant='h1' sx={{ fontSize: '1.6rem', fontWeight: 600 }}>
                  Latest 🎊
                </Typography>
              </Box>
            </Stack>

            {isValidArray(trendingTags.data) && (
              <Box sx={{ pb: 3, pl: 2 }}>
                <HorizontalScroller disableArrow>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='flex-start'
                    spacing={1}
                  >
                    {trendingTags.data?.map((item) => (
                      <Link
                        key={item?.id ?? item?.tagId}
                        to={AppRoutes.postsByTag({
                          id: item?.id ?? item?.tagId,
                          tagName: lowerCase(item.name).replaceAll(' ', '-'),
                        })}
                      >
                        <Chip
                          label={startCase(item.name)}
                          variant='outlined'
                          icon={<BsHash />}
                          sx={{
                            mr: 0.5,
                            fontWeight: 500,
                            color: 'GrayText',
                            fontSize: '0.9rem',
                            p: 0.1,
                            '&:hover': {
                              borderColor: colors.blue[300],
                              color: colors.blue[500],
                              svg: { color: colors.blue[300] },
                            },
                          }}
                        />
                      </Link>
                    ))}
                  </Stack>
                </HorizontalScroller>
              </Box>
            )}

            <Box pl={rv(0, 2)}>
              <AdUnit />
            </Box>
            <ErrorState
              show={
                infiniteRecentPublishedQuery.pageData.count <= 0 &&
                infiniteRecentPublishedQuery.isError
              }
              height={fullHeightWithNav}
            >
              {infiniteRecentPublishedQuery.isInitialLoading ? (
                <Stack spacing={5} pt={3}>
                  {generateRandomArrayWithIndexes(5).map((item) => (
                    <CardSkeleton key={item} />
                  ))}
                </Stack>
              ) : (
                <>
                  {rencentlyPublishedList}
                  <EndComponent
                    hasNextPage={!!infiniteRecentPublishedQuery.hasNextPage}
                    isError={infiniteRecentPublishedQuery.isError}
                    isFetchingNextPage={infiniteRecentPublishedQuery.isFetchingNextPage}
                    loadMore={infiniteRecentPublishedQuery.loadMore}
                    height='3rem'
                  />
                </>
              )}
            </ErrorState>

            {infiniteRecentPublishedQuery.isFetchingNextPage && (
              <LinearProgress
                sx={{
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 11,
                  height: '0.3rem',
                  borderRaius: '3rem 3rem 0 0',
                  boxShadow: '0 -30px 50px rgba(0, 145, 255, 0.831)',
                }}
              />
            )}
            {drawer.data?.id && (
              <LazyRoute>
                <DetailsDrawer open={drawer.open} onClose={drawer.onClose} data={drawer?.data} />
              </LazyRoute>
            )}
          </Box>

          <Hidden lgDown>
            <Stack
              width={{ xs: '100%', lg: '40%' }}
              sx={{
                height: '100%',
                py: 2,
                pr: rv(0, 2),
                pl: rv(0, 3),
                overflowY: 'scroll',
                zIndex: 1,
                background: (theme) => lighten(theme.palette.bg.blue.aliceBlue, 0.5),
              }}
            >
              <Stack direction='row' alignItems='center' justifyContent='flex-start'>
                <Box width='100%' sx={{ pb: 2 }}>
                  <Typography variant='h1' sx={{ fontSize: '1.6rem', fontWeight: 600 }}>
                    Community
                  </Typography>
                </Box>
              </Stack>
              <AddPostBar />

              {suggested.data && isValidArray(suggested.data) && (
                <Box sx={{ my: 3 }} onClick={openCommunity}>
                  <Typography variant='body2' sx={{ fontWeight: 700, mb: 1 }}>
                    Suggested profiles
                  </Typography>
                  <ProfileSuggestions suggested={suggested.data} slidePerView={2} />
                </Box>
              )}

              <Box sx={{ my: 1 }} onClick={openCommunity}>
                <Cards />
              </Box>

              <Box sx={{ pb: 3 }}>
                <Box
                  width='100%'
                  sx={{
                    py: 5,
                    px: 3,
                    borderRadius: '1rem',
                  }}
                >
                  <Typography
                    sx={{ fontSize: '1.1rem', fontWeight: 500, textAlign: 'center', mb: 1 }}
                  >
                    Follow Frulow
                  </Typography>
                  <Footer />
                </Box>
              </Box>
            </Stack>
          </Hidden>
        </Stack>
      </Container>
    </>
  );
}

export default HomePage;
