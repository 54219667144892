import { useCallback, useMemo } from 'react';

import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useAppSelector } from '@app/hooks';
import { getRootUser } from '@app/root/slice';
import { useConfirmBox } from '@hoc/confirm.context';
import { useToast } from '@hooks/useToast';
import { queryKeys } from 'api';
import { addFeedPostComment, deleteFeedPostComment, getFeedPostComments } from 'api/feed';
import { GetPostComments } from 'api/feed/types';

interface Props {
  postId: number;
  parentId?: number;
  enabled: boolean;
}

function useFeedComment({ postId, parentId, enabled = true }: Props) {
  const { askConfirm } = useConfirmBox();
  const user = useAppSelector(getRootUser);
  const queryClient = useQueryClient();
  const { promiseToast } = useToast();

  const deleteComment = useMutation(deleteFeedPostComment);

  const addComment = useMutation(({ text, parentId }: { text: string; parentId?: number }) =>
    addFeedPostComment(postId, text, parentId)
  );

  const comments = useInfiniteQuery(
    [queryKeys.getFeedPostComments, postId, parentId],
    ({ pageParam = 1 }) => getFeedPostComments(postId, pageParam, parentId),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextPage;
      },
      enabled: !!postId && enabled,
    }
  );

  const loadMore = () => {
    if (comments.isFetchingNextPage || comments.isInitialLoading || !comments.hasNextPage) return;
    // Increase the page number when loading more
    comments.fetchNextPage();
  };

  const items = useMemo(() => {
    return (
      comments.data?.pages?.reduce(
        (prev, curr) => [...prev, ...curr.pageData],
        [] as GetPostComments[]
      ) ?? []
    );
  }, [comments.data]);

  const updateLocalState = useCallback(
    (_parentId?: number) => {
      if (_parentId) {
        const queryData: any = queryClient.getQueriesData([
          queryKeys.getFeedPostComments,
          postId,
          _parentId,
        ]);

        if (queryData?.filter(Boolean)?.length <= 1) {
          queryClient.refetchQueries([queryKeys.getFeedPostComments, postId, null], {
            refetchPage(lastPage, index, allPages: any[]) {
              return lastPage.pageData.some((sitem: any) => sitem.id === _parentId);
            },
            exact: true,
            type: 'all',
          });
          return;
        }
      }

      queryClient.refetchQueries([queryKeys.getFeedPostComments, postId, _parentId ?? null], {
        exact: true,
        type: 'all',
      });
    },
    [postId, queryClient]
  );

  const handleCommentAdd = useCallback(
    async (text: string, parentId?: number) => {
      try {
        if (!text.trim()) return;

        await promiseToast(addComment.mutateAsync({ text, parentId }), {
          error: 'An error occurred while adding comment.',
          loading: 'Adding comment...',
          success: (data) => {
            if (!user) comments.refetch({ type: 'all' });
            else updateLocalState(data.parentId);
            return 'Successfully Created.';
          },
        });
        return true;
      } catch (error) {
        console.error(error);
      }
    },
    [addComment, comments, promiseToast, updateLocalState, user]
  );

  const updateCommentDeleteLocalState = useCallback(
    (commentId: number) => {
      const parentId = items.find((item) => item.id === commentId)?.parentId;
      queryClient.refetchQueries([queryKeys.getFeedPostComments, postId, parentId], {
        type: 'all',
      });
    },
    [items, postId, queryClient]
  );

  const handleCommentDelete = useCallback(
    async (commentId: number) => {
      try {
        const confirmed = await askConfirm(
          'Delete comment?',
          'Are you sure. This action cannot be undone.'
        );

        if (!confirmed) return;

        await promiseToast(deleteComment.mutateAsync(commentId), {
          error: 'An error occurred while deleting comment.',
          loading: 'Deleting comment...',
          success: () => {
            updateCommentDeleteLocalState(commentId);
            return 'Successfully deleted.';
          },
        });
        return true;
      } catch (error) {
        console.error(error);
      }
    },
    [askConfirm, deleteComment, promiseToast, updateCommentDeleteLocalState]
  );

  return { comments, loadMore, items, handleCommentAdd, addComment, handleCommentDelete };
}

export default useFeedComment;
