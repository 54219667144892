import React, { useCallback, useRef } from 'react';

import { LinearProgress, Stack, Typography } from '@mui/material';

import { ButtonWithLoader } from '@components/ButtonWithLoader';
import useIntersection from '@hooks/useIntersection';

import CardItem from './CardItem';
import useFeedPosts from '../useFeedPosts';

function Cards() {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const { trendingPosts, items, loadMore } = useFeedPosts();

  useIntersection(
    loadMoreRef,
    100,
    loadMore,
    trendingPosts.isFetchingNextPage || trendingPosts.isInitialLoading
  );

  const renderCard = useCallback((item: (typeof items)[number], index: number) => {
    // Render your list item here
    return <CardItem data={item} key={`community-post-${item.id}`} index={index} />;
  }, []);

  return (
    <>
      <Typography variant='body2' sx={{ fontWeight: 700, mb: 2 }}>
        Trending posts 🔥
      </Typography>
      <Stack direction='column' spacing={2}>
        {trendingPosts.isLoading && <LinearProgress sx={{ height: '2px' }} />}
        {items.map(renderCard)}
        {trendingPosts.hasNextPage && trendingPosts.isSuccess ? (
          <ButtonWithLoader
            sx={{ py: 1, fontSize: '0.9rem' }}
            fullWidth
            isLoading={trendingPosts.isFetchingNextPage || trendingPosts.isInitialLoading}
            ref={loadMoreRef}
          >
            {trendingPosts.isFetching ? 'Loading more...' : 'Load more'}
          </ButtonWithLoader>
        ) : (
          !trendingPosts.isFetching && (
            <Typography
              sx={{ fontSize: '1rem', color: 'GrayText', textAlign: 'center', fontWeight: 700 }}
            >
              No more items 🥹
            </Typography>
          )
        )}
      </Stack>
    </>
  );
}

export default Cards;
