import { colors, lighten } from '@mui/material';
import { orange } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const primaryColor = '#0088FF';
// generate a secondary color based on the primary color
const secondaryColor = orange[500];

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: primaryColor,
      light: '#4da6ff',
      dark: '#005cb3',
      contrastText: '#fff',
    },
    secondary: {
      main: secondaryColor,
      light: '#ff9e40',
      dark: '#c66900',
      contrastText: '#fff',
    },
    text: {
      primary: '#222',
      secondary: '#444',
    },
    background: {
      default: '#fff',
      paper: '#f4f4f4',
    },
    bg: {
      blue: {
        aliceBlue: '#F0F8FF',
        superBlue: '#002633',
      },
    },
    border: {
      gray: {
        mid: '#D5E0D5',
        light: '#F2F2F2',
      },
    },
    notification: {
      highlight: lighten(colors.blue[50], 0.4),
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '0.6rem',
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filled: {
          styles: {
            boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
        color: 'primary',
      },
      styleOverrides: {
        root: () => ({
          padding: '0.8rem 3rem',
          fontSize: '1rem',
          borderRadius: '0.8rem',
          textTransform: 'capitalize',
        }),
      },
    },
    MuiInput: {
      defaultProps: {
        sx: {
          borderRadius: 4,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        sx: {
          borderRadius: 8,
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        variant: 'rectangular',
      },
      styleOverrides: {
        root: {
          borderRadius: '0.5em',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        sx: { textTransform: 'capitalize' },
      },
    },
    MuiAvatar: {
      defaultProps: {
        imgProps: {
          loading: 'lazy',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
          boxShadow:
            'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          borderColor: lighten(colors.grey[400], 0.4),
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '0.4rem',
          boxShadow:
            'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
        },
      },
    },
  },
});

export default lightTheme;
