import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Divider,
  IconButton,
  Modal,
  Paper,
  TextareaAutosize,
  Tooltip,
  Typography,
  colors,
  darken,
  lighten,
  styled,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useDropzone } from 'react-dropzone';
import { BsImage, BsX } from 'react-icons/bs';

import { ButtonWithLoader } from '@components/ButtonWithLoader';
import { StackRow } from '@components/StackRow';
import { useToast } from '@hooks/useToast';
import { preventDefaultWrapper } from '@utility/button';
import { rv } from '@utility/styles';
import { addFeedPost } from 'api/feed';

interface AddPostModalProps {
  open: boolean;
  onClose: () => void;
}

interface ImagePreviewProps {
  src: string;
  onRemove: () => void;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ src, onRemove }) => (
  <PreviewItem
    variant='outlined'
    sx={{
      position: 'relative',
      display: 'inline-block',
      marginRight: '10px',
      boxShadow: 'none',
    }}
  >
    <IconButton
      onClick={preventDefaultWrapper(onRemove, true)}
      sx={{
        position: 'absolute',
        top: '4px',
        right: '2px',
        background: 'error',
        border: 'none',
        cursor: 'pointer',
        bgcolor: lighten('#000', 0.4),
        padding: 0,
        color: '#fff',
        '&:hover': {
          bgcolor: colors.red[700],
        },
      }}
      size='small'
    >
      <BsX />
    </IconButton>
    <img className='preview-image' src={src} alt='Preview' />
  </PreviewItem>
);

const AddPostModal: React.FC<AddPostModalProps> = ({ open, onClose }) => {
  const { promiseToast } = useToast();
  const [postContent, setPostContent] = useState<string>('');
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const postCreate = useMutation(addFeedPost);

  const handleImageChange = (acceptedFiles: File[]) => {
    const newSelectedImages = [
      ...selectedImages,
      ...acceptedFiles.slice(0, 5 - selectedImages.length),
    ];
    setSelectedImages(newSelectedImages);

    const newImagePreviews = newSelectedImages.map((file) => URL.createObjectURL(file));
    setImagePreviews(newImagePreviews);
  };

  const handleRemoveImage = (index: number) => {
    const newSelectedImages = [...selectedImages];
    newSelectedImages.splice(index, 1);
    setSelectedImages(newSelectedImages);

    const newImagePreviews = newSelectedImages.map((file) => URL.createObjectURL(file));
    setImagePreviews(newImagePreviews);
  };

  const {
    getRootProps,
    getInputProps,
    open: dropZoneOpen,
  } = useDropzone({
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg'],
    },
    onDrop: handleImageChange,
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false),
    maxSize: 5 * 1024 * 1024,
    maxFiles: 5 - selectedImages.length,
    disabled: selectedImages.length >= 5,
  });

  const handleSubmit = useCallback(async () => {
    try {
      await promiseToast(postCreate.mutateAsync({ images: selectedImages, text: postContent }), {
        error: (error) => error?.message || 'An error occurred while submitting the post.',
        loading: 'Submitting post...',
        success: () => {
          // Reset states after successful submission.
          setPostContent('');
          setImagePreviews([]);
          setSelectedImages([]);

          // Close the modal
          onClose();

          return 'Post submitted successfully.';
        },
      });

      return true;
    } catch (error) {
      console.error(error);
    }
  }, [promiseToast, postCreate, selectedImages, postContent, onClose]);

  useEffect(() => {
    // Cleanup image previews when component unmounts
    return () => {
      imagePreviews.forEach((preview) => URL.revokeObjectURL(preview));
    };
  }, [imagePreviews]);

  return (
    <Modal open={open} onClose={onClose} slotProps={{ backdrop: { sx: { bgcolor: '#e3f2fdf2' } } }}>
      <Paper
        sx={{
          maxWidth: rv('95vw', 'sm'),
          margin: 'auto',
          background: isDragging ? '#e6f7ff' : '#fff',
          borderRadius: 3,
          border: isDragging ? 'dotted 2px #007bff' : 'none',
          boxSizing: 'border-box',
          transition: 'background 0.3s, border 0.3s',
          mt: 10,
          overflow: 'hidden',
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        }}
      >
        <Typography
          variant='h2'
          sx={{ p: 2, fontSize: '1.4rem', textAlign: 'center', color: 'MenuText', fontWeight: 600 }}
        >
          Add Post
        </Typography>

        <Tooltip title='Close'>
          <CloseButton onClick={onClose}>
            <BsX />
          </CloseButton>
        </Tooltip>
        <Divider variant='fullWidth' />

        <Box>
          <TextArea
            minRows={4}
            value={postContent}
            onChange={(e) => setPostContent(e.target.value)}
            placeholder='Write here...'
          />
        </Box>

        {/* Image Upload */}
        <ImagePreviewBox sx={{ mb: 1, px: 2 }} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragging ? (
            <Typography variant='caption'>Drop the image here...</Typography>
          ) : (
            <>
              {imagePreviews.map((preview, index) => (
                <ImagePreview key={index} src={preview} onRemove={() => handleRemoveImage(index)} />
              ))}
            </>
          )}
        </ImagePreviewBox>

        <ActionRow justifyContent='space-between' sx={{ p: 1 }}>
          <Tooltip title='Add file'>
            <IconButton onClick={dropZoneOpen} disabled={selectedImages.length >= 5}>
              <BsImage />
            </IconButton>
          </Tooltip>
          <ButtonWithLoader
            variant='contained'
            color='primary'
            onClick={handleSubmit}
            disabled={(!postContent?.trim() && !selectedImages?.length) || postCreate.isLoading}
            isLoading={postCreate.isLoading}
          >
            Submit
          </ButtonWithLoader>
        </ActionRow>
      </Paper>
    </Modal>
  );
};

export default AddPostModal;

const ImagePreviewBox = styled(StackRow)(({ theme }) => ({
  '.preview-image': {
    maxWidth: '100%',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  top: '0.5em',
  right: '2em',
  background: theme.palette.error.dark,
  color: '#fff',
  fontSize: '3rem',
  [theme.breakpoints.down('lg')]: {
    top: '0.2em',
    right: '0.5em',
    width: '0.7em',
    height: '0.7em',
  },
  '&:hover': {
    background: darken(theme.palette.error.dark, 0.2),
  },
}));

const TextArea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  border: 'none',
  resize: 'none',
  fontSize: '1rem',
  padding: '1rem',
  outline: 'none',
  overflow: 'none',
  maxHeight: '40vh',
}));

const ActionRow = styled(StackRow)(({ theme }) => ({
  backgroundColor: lighten(colors.blue[50], 0.6),

  '.MuiButton-root': {
    padding: '0.5rem 2rem',
    fontSize: '0.95rem',
  },

  '.MuiIconButton-root': {
    '&:not(.Mui-disabled)': {
      color: theme.palette.primary.main,
    },
  },
}));

const PreviewItem = styled(Paper)(({ theme }) => ({
  height: '100px',
}));
