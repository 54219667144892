import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';

import store from '@app/store';
import { defaultToasterOptions } from '@hooks/useToast';
import AppThemeProvider from '@libs/AppThemeProvider';
import reactQuery from '@libs/reactQuery';
import AppRouter from '@router/AppRouter';

import '@styles/globals.css';
import '@styles/scrollbar.css';

const queryClient = new QueryClient(reactQuery);

export default function App() {
  return (
    <Provider store={store}>
      <AppThemeProvider>
        <QueryClientProvider client={queryClient}>
          <AppRouter />
          <ReactQueryDevtools />
          <Toaster toastOptions={defaultToasterOptions} />
        </QueryClientProvider>
      </AppThemeProvider>
    </Provider>
  );
}
