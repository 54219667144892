import React from 'react';

import { Avatar, Button, Paper, Stack, Typography } from '@mui/material';

import { ButtonWithLoader } from '@components/ButtonWithLoader';
import { TextTruncate } from '@components/TexTruncate';
import useAuthor from '@hooks/useAuthor';
import usePermission from '@hooks/usePermission';
import { GetUserToFollowSuggestions } from 'api/feed/types';

interface Props {
  data: GetUserToFollowSuggestions;
}

function ProfileItem({ data }: Readonly<Props>) {
  const { secureActionWrapper } = usePermission();
  const { handleFollowUseById, isFollowed, withLink } = useAuthor({
    author: {
      fullName: '',
      id: data.id,
      profilePicUrl: data.profilePicUrl,
      userName: data.userName,
    },
  });
  return (
    <Paper
      sx={{
        cursor: 'pointer',
        height: '9em',
        p: 1.6,
        py: 2,
        borderRadius: 3,
        bgcolor: 'white',
        my: 1,
      }}
    >
      <Stack
        justifyContent='space-between'
        alignItems='center'
        direction='column'
        sx={{ height: '100%' }}
      >
        {withLink(
          <Stack justifyContent='center' alignItems='center' direction='column'>
            <Avatar src={data.profilePicUrl as string} sx={{ width: '3.6rem', height: '3.6rem' }}>
              {data.firstName.slice(0, 1)}
            </Avatar>
            <Typography textAlign='center' variant='body2' sx={{ fontWeight: 700, mt: 1 }}>
              {`${data.firstName} ${data.lastName}`}
            </Typography>
            <Typography
              textAlign='center'
              variant='body2'
              sx={{ fontSize: '0.75rem' }}
            >{`@${data.userName}`}</Typography>
          </Stack>
        )}
        <ButtonWithLoader
          onClick={secureActionWrapper(handleFollowUseById)}
          variant={isFollowed.data?.followed ? 'text' : 'contained'}
          fullWidth
          sx={{ p: 0.5, fontSize: '0.9rem', mt: 1 }}
          boxProps={{ width: '100%' }}
          isLoading={isFollowed.isLoading}
        >
          {isFollowed.data?.followed ? 'Followed' : 'Follow'}
        </ButtonWithLoader>
      </Stack>
    </Paper>
  );
}

export default ProfileItem;
