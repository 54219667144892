import { compressImage } from '@components/PicCropper/utils/compressor';
import { isValidArray } from '@utility/array';

import * as Types from './types';
import API from '../_axios';

function getUrl(endpoint: string) {
  return `/feed${endpoint}`;
}

export const addFeedPost = async ({ images, text }: Types.FeedPostAddPayload) => {
  if (!isValidArray(images) && !text) throw new Error('No content to post');

  const formData = new FormData();

  // Use map to create an array of promises
  const compressedImagesPromises = images?.map(async (image) => {
    const compressedImage = await compressImage(image, 0.5, 1920);
    if (!compressedImage) throw new Error('Error compressing images');
    formData.append(`files`, compressedImage);
  });

  // Wait for all promises to resolve
  if (compressedImagesPromises) await Promise.all(compressedImagesPromises);

  if (text) formData.append('text', text);

  const result = await API.put<Types.FeedPostAddResult>({
    url: getUrl('/post'),
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return result.data;
};

export const getTrendingFeedPosts = async (page: number) => {
  const result = await API.get<Types.FeedPostTrendingPostsResult[]>({
    url: getUrl('/post'),
    params: { page },
  });
  return {
    nextPage: (result?.data?.length ?? 0) >= 20 ? page + 1 : undefined,
    pageData: result.data,
  };
};

export const voteFeedPostById = async (id: number, isUpvote: boolean) => {
  const result = await API.put<{ success: boolean }>({
    url: getUrl(`/post/${id}`),
    data: { isUpvote },
  });
  return result.data;
};

export const getPostStatsById = async (id: number) => {
  const result = await API.get<Types.FeedPostLiveStats>({
    url: getUrl(`/post/${id}`),
  });
  return result.data;
};

export const delelteFeedPostById = async (id: number) => {
  const result = await API.Delete<{ success: true }>({
    url: getUrl(`/post/${id}`),
  });
  return result.data;
};

export const getUserToFollowSuggestions = async () => {
  const result = await API.get<Types.GetUserToFollowSuggestions[]>({
    url: getUrl(`/suggest/users`),
  });
  return result.data;
};

export const getFeedPostComments = async (postId: number, page: number, parentId?: number) => {
  const result = await API.get<Types.GetPostComments[]>({
    url: getUrl(`/comment/${postId}`),
    params: { parentId, page },
  });
  return {
    nextPage: (result?.data?.length ?? 0) >= 20 ? page + 1 : undefined,
    pageData: result.data,
  };
};

export const addFeedPostComment = async (postId: number, text: string, parentId?: number) => {
  const result = await API.put<Types.AddPostCommentResponse>({
    url: getUrl(`/comment/${postId}`),
    data: { parentId, text },
  });
  return result.data;
};

export const deleteFeedPostComment = async (commentId: number) => {
  const result = await API.Delete<{ success: true }>({
    url: getUrl(`/comment/${commentId}/delete`),
  });
  return result.data;
};
