import { lazy, useCallback, useMemo, useState } from 'react';

import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  colors,
  styled,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  BsBarChartLine,
  BsBarChartLineFill,
  BsBell,
  BsBellFill,
  BsBoxArrowRight,
  BsChatSquareText,
  BsCollection,
  BsCollectionFill,
  BsHouseDoor,
  BsHouseDoorFill,
  BsPeople,
  BsPeopleFill,
  BsPersonCircle,
  BsPersonFill,
} from 'react-icons/bs';
import { Link, NavLink } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ModalTypes, getRootUser, rootActions } from '@app/root/slice';
import { AppRoutes } from '@constants/appRoutes';
import useFeatures from '@hooks/useFeatures';
import usePermission from '@hooks/usePermission';
import { LazyRoute } from '@router/LazyRoute';
import { isNumberValid } from '@utility/number';
import { clearAllSessionTokens } from '@utility/session';
import { queryKeys } from 'api';

import { useNotificationPanel } from './NotificationPanel';
import { StackRow } from './StackRow';

const NotificationPanel = lazy(() =>
  import('./NotificationPanel').then((res) => ({ default: res.NotificationPanel }))
);
const DraftSelectPopover = lazy(() =>
  import('./DraftSelectPopover').then((res) => ({ default: res.DraftSelectPopover }))
);
const Popper = lazy(() => import('./Popper').then((res) => ({ default: res.Popper })));

export function Appbar() {
  const notifPanel = useNotificationPanel();
  const features = useFeatures();
  const { secureActionWrapper, isUserValid } = usePermission();
  const user = useAppSelector(getRootUser);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const getOwnProfileQuery = queryClient.getQueryState([queryKeys.getUserProfile]);

  const [drafSelectorPopoverEl, setDrafSelectorPopoverEl] = useState<HTMLButtonElement | null>(
    null
  );

  const handleSubmitClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDrafSelectorPopoverEl(event.currentTarget);
  };

  const handleDraftSelectorClose = () => setDrafSelectorPopoverEl(null);

  const handleAuthClick = useCallback(() => {
    dispatch(
      rootActions.setActiveModal(
        getOwnProfileQuery?.status === 'success' || user?.id ? ModalTypes.PROFILE : ModalTypes.AUTH
      )
    );
  }, [dispatch, getOwnProfileQuery?.status, user?.id]);

  const handleSearchShow = () => {
    dispatch(rootActions.setActiveModal(ModalTypes.SEARCH));
  };

  const tabs = useMemo(() => {
    return [
      {
        title: 'Home',
        value: AppRoutes.home,
        icon: <BsHouseDoor />,
        activeIcon: <BsHouseDoorFill />,
      },
      ...(features.discussionEnabled
        ? [
            {
              title: 'Community',
              value: AppRoutes.community,
              icon: <BsPeople />,
              activeIcon: <BsPeopleFill />,
            },
          ]
        : []),
      {
        title: 'Dashboard',
        value: AppRoutes.dashboard,
        icon: <BsBarChartLine />,
        activeIcon: <BsBarChartLineFill />,
      },
      {
        title: 'Collections',
        value: AppRoutes.collection,
        icon: <BsCollection />,
        activeIcon: <BsCollectionFill />,
      },
      ...(user
        ? [
            {
              title: 'Profile',
              value: AppRoutes.profile({ id: `${user.userName}` }),
              icon: <BsPersonCircle />,
              activeIcon: <BsPersonFill />,
            },
          ]
        : []),
    ];
  }, [features.discussionEnabled, user]);

  const PopperElement = useMemo(() => {
    return (
      <Box
        width='10em'
        sx={{ zIndex: 1111 }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Button fullWidth variant='text' startIcon={<BsPersonCircle />} onClick={handleAuthClick}>
          Edit profile
        </Button>
        <Link
          to='#'
          onClick={(e) => {
            window.open(
              'mailto:' +
                'support@maarkar.in' +
                '?subject=' +
                `Feedback: Frulow.com - User #${user?.id as number}`
            );
            e.preventDefault();
          }}
          target='blank'
          rel='noopener noreferrer'
        >
          <Button fullWidth variant='text' startIcon={<BsChatSquareText />}>
            Give Feedback
          </Button>
        </Link>
        <Button
          fullWidth
          variant='text'
          startIcon={<BsBoxArrowRight />}
          onClick={clearAllSessionTokens}
        >
          Logout
        </Button>
      </Box>
    );
  }, [handleAuthClick, user?.id]);

  return (
    <AppBar variant='outlined' position='static' color='transparent' sx={{ zIndex: 1111 }}>
      <Toolbar sx={{ py: 0, minHeight: '55px!important' }}>
        <StackRow spacing={1} sx={{ mr: 3 }}>
          <Avatar src='/logo_512x512.png' />
          <Typography
            variant='h6'
            component='div'
            sx={{ flexGrow: 1, fontSize: '1rem', fontWeight: 700 }}
          >
            Frulow
          </Typography>
        </StackRow>

        <TextField
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            sx: {
              fontSize: '1rem',
            },
          }}
          variant='standard'
          placeholder='Search Libs...'
          onClick={handleSearchShow}
          sx={{
            bgcolor: colors.blueGrey[50],
            borderRadius: '0.5rem',
            height: '2.6rem',
            width: '14rem',
            p: '0.3rem',
            px: 1.8,
            mr: 4,
          }}
        />

        <HeaderTabs spacing={1} flexGrow={1}>
          {tabs.map((item) => (
            <NavLink key={item.title} to={item.value}>
              {({ isActive }) => (
                <Button
                  variant={isActive ? 'outlined' : 'text'}
                  startIcon={isActive && item.activeIcon ? item.activeIcon : item.icon}
                  sx={{
                    p: 1,
                    px: 2,
                    py: 0,
                    height: '50px',
                    fontSize: '0.95rem',
                    border: 'none !important',
                    ...(!isActive && { color: colors.blueGrey[500] }),
                  }}
                >
                  {item.title}
                </Button>
              )}
            </NavLink>
          ))}
        </HeaderTabs>

        <StackRow alignItems='center' justifyContent='flex-end' spacing={2}>
          {features.notif && isUserValid() && (
            <Box>
              <NotificationIcon
                $isActive={isNumberValid(notifPanel.unseenNotifCount?.data?.unseenCount) > 0}
                onClick={notifPanel.handleNotificationsPanelOpen}
                onMouseOver={notifPanel.handleNotificationsPanelOpen}
              >
                <Badge
                  badgeContent={
                    isNumberValid(notifPanel.unseenNotifCount?.data?.unseenCount) > 0
                      ? `${notifPanel.unseenNotifCount?.data?.unseenCount}`
                      : undefined
                  }
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  color='error'
                >
                  {isNumberValid(notifPanel.unseenNotifCount?.data?.unseenCount) > 0 ? (
                    <BsBellFill size={24} />
                  ) : (
                    <BsBell size={24} />
                  )}
                </Badge>
              </NotificationIcon>
              <LazyRoute>
                <NotificationPanel
                  anchorEl={notifPanel.anchorEl}
                  data={notifPanel.notifQuery?.data || []}
                  isMenuOpen={Boolean(notifPanel.anchorEl)}
                  isLoading={notifPanel.notifQuery.isLoading}
                  onReadAll={notifPanel.handleMarkAllRead}
                  onNotifClick={notifPanel.handleNotificationClick}
                  handleMenuClose={notifPanel.handleNotificationPanelClose}
                />
              </LazyRoute>
            </Box>
          )}

          <Button
            onClick={secureActionWrapper(handleSubmitClick)}
            variant='contained'
            color='primary'
            sx={{ p: 1, px: 2, fontSize: '0.95rem', my: 0 }}
          >
            Submit
          </Button>

          {drafSelectorPopoverEl && (
            <LazyRoute>
              <DraftSelectPopover
                anchorEl={drafSelectorPopoverEl}
                onClose={handleDraftSelectorClose}
              />
            </LazyRoute>
          )}

          <Tooltip title={user?.id ? undefined : 'Login'} placement='bottom'>
            <IconButton
              disabled={getOwnProfileQuery?.status === 'loading'}
              size='small'
              edge='end'
              color='inherit'
              aria-label='menu'
              onClick={handleAuthClick}
              sx={{ position: 'relative' }}
            >
              {getOwnProfileQuery?.status === 'loading' && <AvatarCircualrSpinner />}
              {user?.profilePicUrl ? (
                <LazyRoute>
                  <Popper popperElement={PopperElement}>
                    <Avatar src={user.profilePicUrl}>{user.firstName}</Avatar>
                  </Popper>
                </LazyRoute>
              ) : (
                <BsPersonCircle size='1.8rem' />
              )}
            </IconButton>
          </Tooltip>
        </StackRow>
      </Toolbar>
    </AppBar>
  );
}

const AvatarCircualrSpinner = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  top: -0.5,
  left: 0,
  width: '100%',
  height: '100%',
}));

const NotificationIcon = styled(Box)<{ $isActive: boolean }>(({ $isActive }) => ({
  '.MuiBadge-badge': {
    border: '2px solid #fff',
    ...($isActive && {
      boxShadow: '0 2px 3px rgba(0,0,0,0.1)',
    }),
  },
  ...($isActive && {
    svg: {
      fill: colors.orange[700],
    },
  }),
}));

const HeaderTabs = styled(StackRow)(({ theme }) => ({
  '.MuiButton-root': {
    '.MuiButton-startIcon': {
      width: '100%',
      margin: 0,
      svg: {
        margin: '0 auto',
      },
    },
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem',
    fontSize: '0.75rem',
    fontWeight: 700,
  },
}));
