import { useMemo, useState } from 'react';

import { Box, Button, Divider, LinearProgress, Tooltip, Typography, styled } from '@mui/material';
import {
  BsChatSquareDots,
  BsHandThumbsDown,
  BsHandThumbsDownFill,
  BsHandThumbsUp,
  BsHandThumbsUpFill,
  BsShare,
} from 'react-icons/bs';

import { StackRow } from '@components/StackRow';
import { isNumberValid } from '@utility/number';
import { numberToWords } from '@utility/numberToWords';
import { pluralizeText } from '@utility/pluralizeText';
import { FeedPostTrendingPostsResult } from 'api/feed/types';

import Comments from './Comments/Comments';
import { PostUpvoteAction } from './useCardItem';

type Props = Pick<
  FeedPostTrendingPostsResult,
  'comments' | 'likes' | 'id' | 'dislikes' | 'shares' | 'viewerVoteValue'
> & { onVote: PostUpvoteAction; isLoading?: boolean; disableActionBar?: boolean };

function CardActions({
  comments,
  dislikes,
  id,
  likes,
  shares,
  onVote,
  viewerVoteValue,
  isLoading,
  disableActionBar,
}: Readonly<Props>) {
  const [commentOpen, setCommentOpen] = useState(false);

  const handleCommentToggle = () => setCommentOpen((prev) => !prev);

  const viewerVote = useMemo(() => {
    if (
      viewerVoteValue === null ||
      viewerVoteValue === undefined ||
      Number.isNaN(+viewerVoteValue)
    ) {
      return undefined;
    }
    return viewerVoteValue > 0 ? 'like' : 'dislike';
  }, [viewerVoteValue]);

  return (
    <>
      <ActionStatsRow spacing={2} justifyContent='space-between'>
        {!!(isNumberValid(likes) || isNumberValid(dislikes)) && (
          <StackRow spacing={2} justifyContent='flex-start'>
            {isNumberValid(likes) > 0 && (
              <Typography>{`${numberToWords(likes ?? '0')} ${pluralizeText(
                'Like',
                +(likes || 0)
              )}`}</Typography>
            )}
            {isNumberValid(dislikes) > 0 && (
              <Typography>{`${numberToWords(dislikes ?? '0')} ${pluralizeText(
                'Dislike',
                +(dislikes || 0)
              )}`}</Typography>
            )}
          </StackRow>
        )}

        {!!(isNumberValid(comments) || isNumberValid(shares)) && (
          <StackRow spacing={2} justifyContent='flex-end'>
            {isNumberValid(comments) > 0 && (
              <Typography>{`${numberToWords(comments ?? '0')} ${pluralizeText(
                'Comment',
                +(comments || 0)
              )}`}</Typography>
            )}
            {isNumberValid(shares) > 0 && (
              <Typography>{`${numberToWords(shares ?? '0')} ${pluralizeText(
                'Share',
                +(shares || 0)
              )}`}</Typography>
            )}
          </StackRow>
        )}
      </ActionStatsRow>

      {isLoading ? <LinearProgress sx={{ my: 1, height: '1px' }} /> : <Divider sx={{ my: 1 }} />}
      <ActionButtonsRow
        spacing={0}
        justifyContent='flex-start'
        sx={{ pb: 1, position: 'relative' }}
      >
        <Button
          variant='text'
          startIcon={viewerVote === 'like' ? <BsHandThumbsUpFill /> : <BsHandThumbsUp />}
          onClick={() => onVote(true)}
          disabled={isLoading}
        >
          Like
        </Button>
        <Button
          variant='text'
          startIcon={viewerVote === 'dislike' ? <BsHandThumbsDownFill /> : <BsHandThumbsDown />}
          onClick={() => onVote(false)}
          disabled={isLoading}
        >
          Dislike
        </Button>
        <Button variant='text' startIcon={<BsChatSquareDots />} onClick={handleCommentToggle}>
          Comment
        </Button>
        {/* <Tooltip title='Enabled soon'>
          <Button variant='text' startIcon={<BsShare />} sx={{ opacity: 0.5 }}>
            Share
          </Button>
        </Tooltip> */}
        {disableActionBar && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bgcolor: '#fff',
              opacity: 0.8,
              filter: 'blur(20px)',
              zIndex: 111,
            }}
          />
        )}
      </ActionButtonsRow>
      {commentOpen && <Comments open={commentOpen} onClose={handleCommentToggle} postId={id} />}
    </>
  );
}

export default CardActions;

const ActionStatsRow = styled(StackRow)(({ theme }) => ({
  '.MuiTypography-root': {
    color: theme.palette.getContrastText('#fff'),
    fontSize: '0.8rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.75rem',
      color: theme.palette.text.secondary,
    },
  },
}));

const ActionButtonsRow = styled(StackRow)(({ theme }) => ({
  '.MuiButton-root': {
    padding: '0.3rem 0rem',
    fontSize: '0.9rem',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.7rem',
      '& .MuiButton-startIcon': {
        svg: {
          fontSize: '1.2rem',
        },
      },
    },
  },
}));
