interface Opts {
  pluralizeOnCount: number;
}

export function pluralizeText(
  text: string,
  count: number,
  pluralForm?: string,
  opts: Partial<Opts> = {}
) {
  if (count === 1 || (opts.pluralizeOnCount && count <= opts.pluralizeOnCount)) {
    // If count is 1 or less than or equal to the specified count, return the singular form
    return text;
  } else {
    // If count is greater than the specified count, use the provided plural form or add "s" to make it plural
    return pluralForm ?? text + 's';
  }
}
