import { useMemo } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';

import { queryKeys } from 'api';
import { getTrendingFeedPosts } from 'api/feed';
import { FeedPostTrendingPostsResult } from 'api/feed/types';

function useFeedPosts() {
  const trendingPosts = useInfiniteQuery(
    [queryKeys.getTrendingFeedPosts],
    ({ pageParam = 1 }) => getTrendingFeedPosts(pageParam),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextPage;
      },
    }
  );

  const loadMore = () => {
    if (
      trendingPosts.isFetchingNextPage ||
      trendingPosts.isInitialLoading ||
      !trendingPosts.hasNextPage
    )
      return;
    // Increase the page number when loading more
    trendingPosts.fetchNextPage();
  };

  const items = useMemo(() => {
    return (
      trendingPosts.data?.pages?.reduce(
        (prev, curr) => [...prev, ...curr.pageData],
        [] as FeedPostTrendingPostsResult[]
      ) ?? []
    );
  }, [trendingPosts.data?.pages]);

  return { trendingPosts, loadMore, items };
}

export default useFeedPosts;
