import { UseInfiniteQueryResult } from '@tanstack/react-query';

import { GetPostComments } from 'api/feed/types';

type PageItem = { id: number } & Record<string, any>;

interface OriginalData<T extends PageItem> {
  pages: { pageData: T[]; [x: string]: any }[];
  [x: string]: any;
}

export const calculateUpdatedStats = (
  isUpvote: boolean | undefined,
  likes: number | null,
  dislikes: number | null,
  previousValue: number | undefined | null
) => {
  let latestVoteValue: number | undefined = isUpvote ? 1 : -1;

  let updatedLikes = likes !== null ? likes : 0;
  let updatedDislikes = dislikes !== null ? dislikes : 0;

  if (previousValue !== null && previousValue !== undefined) {
    // If the user has previously voted
    const previousVoteValue = previousValue === 1 ? 1 : -1;

    // If the latest vote is the same as the previous vote, remove the vote
    if (latestVoteValue === previousVoteValue) {
      // if disliked previously or liked previously (remove those values)
      if (previousVoteValue < 0) updatedDislikes -= 1;
      else updatedLikes -= 1;

      latestVoteValue = undefined;
    } else {
      // If the latest vote is different from the previous vote, update likes and dislikes
      updatedLikes += latestVoteValue === 1 ? 1 : 0;
      updatedDislikes += latestVoteValue === -1 ? 1 : 0;
      updatedLikes -= previousVoteValue === 1 ? 1 : 0;
      updatedDislikes -= previousVoteValue === -1 ? 1 : 0;
    }
  } else {
    // If the user has not previously voted
    updatedLikes += latestVoteValue === 1 ? 1 : 0;
    updatedDislikes += latestVoteValue === -1 ? 1 : 0;
  }

  return {
    likes: typeof updatedLikes === 'number' && updatedLikes > 0 ? `${updatedLikes}` : null,
    dislikes:
      typeof updatedDislikes === 'number' && updatedDislikes > 0 ? `${updatedDislikes}` : null,
  };
};

const modifyDataById = <T extends PageItem>(
  originalData: OriginalData<T>,
  id: number,
  newData: T
): OriginalData<T> => {
  const { pages } = originalData;

  // Find the page and item based on the item's ID
  let found = false;
  const modifiedPages = pages.map((page) => {
    const modifiedPage = { ...page, pageData: [...page.pageData] };
    modifiedPage.pageData = modifiedPage.pageData.map((item) => {
      if (item.id === id) {
        found = true;
        return { ...item, ...newData };
      }
      return item;
    });
    return modifiedPage;
  });

  // If the item is not found, add it to the first page
  if (!found) {
    modifiedPages[0].pageData.unshift(newData);
  }

  // Return the result with the same structure
  return { ...originalData, pages: modifiedPages };
};

export type ModifyCommentActions = 'modify' | 'delete' | 'add';

function modifyComments(
  data: UseInfiniteQueryResult<{
    pageData: GetPostComments[];
    [x: string]: any;
  }>['data'],
  action: ModifyCommentActions,
  comment: GetPostComments
) {
  // Validate input parameters
  if (!data || !data.pages || !Array.isArray(data.pages) || data.pages.length === 0) {
    console.error('Invalid data format');
    return;
  }

  // Helper function to find the index of a comment by its ID
  function findCommentIndexById(pageData: GetPostComments[], commentId: number) {
    return pageData.findIndex((comment) => comment.id === commentId);
  }

  // Perform the specified action
  switch (action) {
    case 'delete':
      // Delete comment by ID
      data.pages.forEach((page) => {
        const commentIndex = findCommentIndexById(page.pageData, comment.id);
        if (commentIndex !== -1) {
          page.pageData.splice(commentIndex, 1);
        }
      });
      break;

    case 'modify':
      // Modify comment by ID
      data.pages.forEach((page) => {
        const commentIndex = findCommentIndexById(page.pageData, comment.id);
        if (commentIndex !== -1) {
          // Update the comment with the new data
          page.pageData[commentIndex] = { ...page.pageData[commentIndex], ...comment };
        }
      });
      break;

    case 'add':
      // Add a new comment
      if (!comment.id || !comment.text || !comment.authorId) {
        console.error('Invalid comment data');
        return;
      }

      // Check if the page length is 0, add a new page
      if (data.pages[0].pageData.length === 0) {
        data.pages[0].pageData.push(comment);
      } else {
        // Add the new comment to the existing page
        data.pages[0].pageData.unshift(comment);
      }
      break;

    default:
      console.error('Invalid action');
      break;
  }

  return data;
}

const infiniteQueryUtils = { modifyDataById, calculateUpdatedStats, modifyComments };
export default infiniteQueryUtils;
