export const queryKeys = {
  getUserProfile: 'getUserProfile',
  loginWithEmail: 'loginWithEmail',
  searchTag: 'searchTag',
  searchLang: 'searchLang',
  createPost: 'createPost',
  getOwnPosts: 'getOwnPosts',
  getOwnPostById: 'getOwnPostById',
  getPostsStatsById: 'getPostsStatsById',
  getTrendingPosts: 'getTrendingPosts',
  getAllPostByUserId: 'getAllPostByUserId',
  getTrendingTags: 'getTrendingTags',
  getPostById: 'getPostById',
  getPostsByTagId: 'getPostsByTagId',
  getPostsByProgLang: 'getPostsByProgLang',
  getTagById: 'getTagById',
  getSimilarTagsByTagId: 'getSimilarTagsByTagId',
  getAllCommentsByPostIdPaginated: 'getAllCommentsByPostIdPaginated',
  getAllNestedCommentsByParentIdPaginated: 'getAllNestedCommentsByParentIdPaginated',
  getAnyUserById: 'getAnyUserById',
  getAllFollowedTags: 'getAllFollowedTags',
  personalizeTagsByUser: 'personalizeTagsByUser',
  getIsPostTrending: 'getIsPostTrending',
  searchPost: 'searchPost',
  getAllCollections: 'getAllCollections',
  getCollectionById: 'getCollectionById',
  getAllNotifs: 'getAllNotifs',
  getUnseenNotifsCount: 'getUnseenNotifsCount',
  getReactionsByPostId: 'getReactionsByPostId',
  getFollowedStatusById: 'getFollowedStatusById',
  getRecentlyPublishedPaginated: 'getRecentlyPublishedPaginated',

  // Community
  getTrendingFeedPosts: 'getTrendingFeedPosts',
  getFeedPostLiveStats: 'getFeedPostLiveStats',
  getUserToFollowSuggestions: 'getUserToFollowSuggestions',
  getFeedPostComments: 'getFeedPostComments',
};

export const mutationKey = {
  createNewPost: 'createNewPost',
  addPostToCollection: 'addPostToCollection',
};
