import React, { useCallback, useMemo, useRef, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import {
  BsChevronDown,
  BsChevronLeft,
  BsFlag,
  BsReply,
  BsSend,
  BsThreeDots,
  BsTrash,
  BsX,
} from 'react-icons/bs';
import { Link as RouterLink } from 'react-router-dom';

import { useAppSelector } from '@app/hooks';
import { getRootUser } from '@app/root/slice';
import { EmptyState } from '@components/EmptyState';
import { Popper } from '@components/Popper';
import { StackRow } from '@components/StackRow';
import { TextAreaAutoSizeStyled } from '@components/TextAreaAutoSize';
import { AppRoutes } from '@constants/appRoutes';
import usePermission from '@hooks/usePermission';
import useResponsive from '@hooks/useResponsive';
import useFeedComment from '@pages/Community/useFeedComment';
import { preventDefaultWrapper } from '@utility/button';
import { getShortestDuration } from '@utility/dates';
import { isNumberValid } from '@utility/number';
import { numberToWords } from '@utility/numberToWords';
import { rv } from '@utility/styles';
import { GetPostComments } from 'api/feed/types';

interface Props {
  open: boolean;
  onClose: () => void;
  postId: number;
  parentId?: number;
}

type ReplyingState = { threadId: number; userName: string };
type ReplyStateChangeAction = (state: ReplyingState) => void;

function Comments({ open, onClose, postId, parentId }: Props) {
  const { isDownLg } = useResponsive();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { secureActionWrapper } = usePermission();
  const user = useAppSelector(getRootUser);
  const { items, comments, handleCommentAdd, addComment } = useFeedComment({
    postId,
    parentId,
    enabled: true,
  });

  const [commentText, setCommentText] = useState('');

  const [replyingTo, setReplyingTo] = useState<ReplyingState | null>(null);

  const handleSetReplyTo: ReplyStateChangeAction = useCallback(({ threadId, userName }) => {
    setReplyingTo({ threadId, userName });
    textAreaRef.current?.focus();
  }, []);

  const onCommentAdd = () => {
    handleCommentAdd(commentText, replyingTo?.threadId).then(() => {
      setCommentText('');
      setReplyingTo(null);
    });
  };

  return (
    <Drawer open={open} onClose={onClose} anchor='bottom' PaperProps={{ sx: { bgcolor: 'white' } }}>
      <Container
        maxWidth='md'
        sx={{ height: '100%', minHeight: '96vh', maxHeight: '96vh', py: 1, mb: rv(10, 0) }}
      >
        <StackRow spacing={0} alignItems='center'>
          <IconButton onClick={onClose} size={isDownLg ? 'small' : 'medium'}>
            <BsChevronLeft />
          </IconButton>
          <Typography sx={{ fontSize: rv('1rem', '1.2rem'), fontWeight: 600 }}>
            Comments ({numberToWords(items?.[0]?.totalCount ?? 0)})
          </Typography>
        </StackRow>

        {comments.isFetching ? (
          <LinearProgress sx={{ height: '2px', my: 1, mb: 3 }} />
        ) : (
          <Divider sx={{ my: 1, mb: 3 }} />
        )}

        <Stack direction='column' spacing={3} sx={{ pb: 30 }}>
          <EmptyState
            description='Be the first one to comment ;)'
            icon='bs'
            show={!items.length && !comments.isLoading}
            title='No comments'
            height='50%'
          >
            {items.map((item, index) => (
              <CommentItem key={item.id} data={item} setReplyState={handleSetReplyTo} />
            ))}
          </EmptyState>
        </Stack>

        <Stack
          sx={{
            position: 'fixed',
            zIndex: 11,
            left: 0,
            width: '100%',
            bottom: rv(50, 0),
          }}
          justifyContent='center'
          alignItems='center'
        >
          <Box
            sx={{
              bgcolor: '#fff',
              maxWidth: '49.5em',
              width: '100%',
              px: rv(1, 3),
              py: 1,
              boxShadow: '0 -10px 10px -10px rgb(0, 0, 0, 0.3)',
            }}
          >
            <StackRow alignItems='center' sx={{ pb: 0.5 }}>
              <Avatar
                title='Your Profile'
                sx={{ width: rv('1.2rem', '1.6rem'), height: rv('1.2rem', '1.6rem'), mr: 1 }}
                src={user?.profilePicUrl as string}
              >
                {user?.firstName?.slice(0, 1) ?? 'U'}
              </Avatar>
              <Typography sx={{ fontSize: '1rem', fontWeight: 700 }}>
                You •{' '}
                <Typography sx={{ color: 'GrayText', fontSize: '0.85rem' }} component='span'>
                  {replyingTo ? (
                    <Chip
                      deleteIcon={<BsX />}
                      label={`Replying to @${replyingTo.userName}`}
                      onDelete={() => setReplyingTo(null)}
                      sx={{ fontSize: '0.75rem', height: '2em' }}
                      color='primary'
                    />
                  ) : (
                    `Add comment`
                  )}
                </Typography>
              </Typography>
            </StackRow>
            <Stack
              direction={rv('row', 'column')}
              alignItems='center'
              justifyContent='space-between'
              sx={{ width: '100%', position: 'relative' }}
            >
              <TextAreaAutoSizeStyled
                maxRows={6}
                placeholder='Write your comment'
                sx={{
                  paddingRight: rv(0, '7rem'),
                }}
                onChange={(e) => setCommentText(e.target.value)}
                ref={textAreaRef}
                value={commentText}
              />
              {isDownLg ? (
                <IconButton
                  onClick={secureActionWrapper(onCommentAdd)}
                  disabled={!commentText?.trim() || addComment.isLoading}
                  size='medium'
                  sx={{ bgcolor: 'primary.main', color: '#fff', ml: 1 }}
                >
                  <BsSend size={20} />
                </IconButton>
              ) : (
                <Button
                  sx={{
                    p: rv('0.4rem 0.6rem', '0.5rem 1.6rem'),
                    alignSelf: 'flex-end',
                    position: rv('static', 'absolute'),
                    bottom: 12,
                    right: 3,
                    fontSize: rv('0.9rem', undefined),
                  }}
                  endIcon={<BsSend />}
                  onClick={secureActionWrapper(onCommentAdd)}
                  disabled={!commentText?.trim() || addComment.isLoading}
                >
                  {replyingTo ? 'Reply' : 'Send'}
                </Button>
              )}
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Drawer>
  );
}

export default Comments;

interface CommentItemProps {
  level?: number;
  data: GetPostComments;
  setReplyState: ReplyStateChangeAction;
}

function CommentItem({ data, setReplyState, level = 0 }: Readonly<CommentItemProps>) {
  const { isOwner } = usePermission();
  const [showReplies, setShowReplies] = useState(false);

  const hasReplies = isNumberValid(data.replies) > 0;
  const replies = useFeedComment({
    postId: data.feedPostId,
    parentId: data.id,
    enabled: showReplies && hasReplies,
  });

  const PopperElement = useMemo(() => {
    return (
      <Box
        sx={{ width: '10em', bgcolor: 'white', py: 1 }}
        onClick={() => preventDefaultWrapper(undefined, true)}
        component='div'
      >
        {isOwner(data.authorId) && (
          <Button
            startIcon={<BsTrash />}
            fullWidth
            variant='text'
            sx={{ p: 0.5, fontSize: '1rem', boxShadow: 'none' }}
            onClick={() => replies.handleCommentDelete(data.id)}
          >
            Delete Post
          </Button>
        )}
        {/* <Button
          startIcon={<BsEyeSlash />}
          fullWidth
          variant='text'
          sx={{ p: 0.5, fontSize: '1rem', boxShadow: 'none' }}
        >
          Hide Post
        </Button>*/}

        <Button
          startIcon={<BsFlag />}
          fullWidth
          variant='text'
          sx={{ p: 0.5, fontSize: '1rem', boxShadow: 'none' }}
          disabled
        >
          Report
        </Button>
      </Box>
    );
  }, [data.authorId, data.id, isOwner, replies]);

  return (
    <StackRow alignItems='flex-start' spacing={rv(1, 2)}>
      <Avatar
        sx={{ width: rv('1.6rem', '2.6rem'), height: rv('1.6rem', '2.6rem') }}
        src={data.authorAvatar}
      >
        {data.authorFullName?.slice(0, 1)}
      </Avatar>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <StackRow justifyContent='space-between'>
          <Typography sx={{ fontSize: rv('0.8rem', '1rem'), fontWeight: 700 }}>
            <Link
              component={RouterLink}
              to={AppRoutes.profile({ id: data.authorUserName })}
              underline='hover'
              color='MenuText'
            >
              {data.authorFullName}
            </Link>{' '}
            <Typography
              component='span'
              sx={{ fontSize: rv('0.7rem', '0.9rem'), color: 'GrayText' }}
            >
              (@{data.authorUserName})
            </Typography>
            <Typography
              component='span'
              sx={{ fontSize: rv('0.65rem', '0.8rem'), color: 'GrayText', fontWeight: 700, ml: 2 }}
            >
              {getShortestDuration(data.createdAt)}
            </Typography>
          </Typography>
          <Popper popperElement={PopperElement}>
            <IconButton size='small'>
              <BsThreeDots />
            </IconButton>
          </Popper>
        </StackRow>

        <StackRow justifyContent='space-between'>
          <Typography sx={{ fontSize: rv('0.9rem', '1rem'), mt: 0.3, whiteSpace: 'pre-line' }}>
            {data.text}
          </Typography>

          {/* <Stack direction='column' alignItems='center' justifyContent='center' sx={{ ml: 1 }}>
            <IconButton sx={{ color: colors.yellow[900] }}>
              <BsFire size={20} />
            </IconButton>
            <Typography sx={{ fontSize: '0.7rem', color: 'GrayText' }}>
              {numberToWords(`200000`, { useShortForm: true, decimalPlaces: 0 })}
            </Typography>
          </Stack> */}
        </StackRow>

        <StackRow spacing={2}>
          {hasReplies && !showReplies && level < 1 && (
            <Link
              sx={{ fontSize: '0.8rem', cursor: 'pointer', fontWeight: 700 }}
              underline='hover'
              onClick={() => setShowReplies(true)}
            >
              Show replies <BsChevronDown />
            </Link>
          )}
          {level < 2 && (
            <Link
              sx={{ fontSize: '0.8rem', cursor: 'pointer', fontWeight: 700 }}
              underline='hover'
              onClick={() => setReplyState({ threadId: data.id, userName: data.authorUserName })}
            >
              Reply <BsReply />
            </Link>
          )}
        </StackRow>

        {hasReplies && showReplies && (
          <Stack direction='column' spacing={2} sx={{ py: 2 }}>
            {replies.items.map((item) => (
              <CommentItem level={1} data={item} key={item.id} setReplyState={setReplyState} />
            ))}
          </Stack>
        )}
        {replies.comments.isFetching && <LinearProgress sx={{ height: '1px' }} />}
      </Box>
    </StackRow>
  );
}
